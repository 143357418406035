
.navbar-container {
    background-color: #fff;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img {
    width: 75%;
    height: auto;
}

.nav-links {
    list-style: none;
    display: flex;
}

.nav-links li {
    margin-right: 20px;
}

.nav-links a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 1.2em;
    transition: color 0.3s ease-in-out;
}

.nav-links a:hover {
    color: #ffd700;
    /* Change the color on hover as desired */
}
