@import "../../node_modules/bulma/css/bulma.min.css"; 

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .introduction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }
  
  .text {
    max-width: 500px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2em;
    color: #8e8a8a;
    margin-bottom: 10px;
  }
  
  .explore-button {
    background-color: #facc12;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .explore-button:hover {
    background-color: #ffa600;
  }
  
  .buy-plans {
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  
  }
  
  .buy-plans h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .buy-plans p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 10px;
  }
  
  .subscription-options {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .subscription-option {
    flex: 0 1 calc(48% - 10px);
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
  }
  
  .subscription-option h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .subscribe-button {
    background-color: #fcd61a;
    color: #fff;
    padding: 8px 15px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .photo-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .photo-gallery a {
    flex: 0 1 calc(33.33% - 20px);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .photo-gallery img {
    display: block;
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .photo-gallery img:hover {
    transform: scale(1.1);
  }
  .tech-info {
    font-size: 1.875rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 8px;
    letter-spacing: -0.8px;
    letter-spacing: -.05rem;
  }
  
  .tech-info-list {
    font-size: 1.25em;
    color: #cb3837;
    text-decoration: none;
    font-weight: 400;
    line-height: 1.4;
  }